import { useState } from "react";
import { useSelector } from "react-redux";
import { Navigator } from "../../shared/components/Navigator";
import {
  ADVENTURE_PICKER_ROUTE,
  ADVENTURE_STORY_ROUTE,
  CHARACTERS_ROUTE,
  DASHBOARD_ROUTE,
  EXERCISE_ROUTE,
} from "../routes";
import {
  BackgroundImage,
  Placeable,
  StyledNavigatorLink,
  StyledLinksContainer,
  StyledTopContainer,
  StyledWrapper,
  StyledTopContainerWrapper,
} from "./StyledAttic";
import { ReactComponent as HouseIcon } from "../../assets/icons/icon_home.svg";
import { ReactComponent as CountIcon } from "../../assets/icons/icon_exercise.svg";
import { LinksDataType } from "./types";
import {
  selectHasActiveAdventure,
  selectCurrentAdventure,
  selectAdventureGroupForActiveAdventure,
} from "../adventurePicker/api/selectors";
import {
  selectCurrentRoute,
  selectNextRoute,
} from "../../shared/store/router/selectors";
import AdventureInfoModal from "../../shared/components/AdventureInfoModal/AdventureInfoModal";
import { selectTheme } from "../profile/api/selectors";
import { getAtticBackgroundImage } from "./AtticBackground";
import { getAtticLink } from "./AtticLinks";
import { MIDDLE_SCHOOL } from "../../shared/constants";

const topButtonStyles = { width: "60px", height: "60px" };
const breakPoint = 1095;
const deviceWidth = document.documentElement.clientWidth;
const vertical = deviceWidth < breakPoint;

export const AtticView = () => {
  const [showAdventureInfoModal, setShowAdventureInfoModal] = useState(false);
  const hasActiveAdventure = useSelector(selectHasActiveAdventure);
  const nextRoute = useSelector(selectNextRoute);
  const currentRoute = useSelector(selectCurrentRoute);
  const theme = useSelector(selectTheme);
  const activeAdventure = useSelector(selectCurrentAdventure);
  const adventureGroup = useSelector(selectAdventureGroupForActiveAdventure);

  const renderAdventureInfoModal = () => setShowAdventureInfoModal(true);

  const getCheckedRoute = (route: string) =>
    (route === CHARACTERS_ROUTE && !hasActiveAdventure) ||
    (nextRoute || {}).route === ADVENTURE_STORY_ROUTE
      ? ""
      : route;

  const adventureButtonLabel = nextRoute ? (nextRoute || {}).text : "ÄVENTYR";

  const renderLink = (label: string, route: string, tempBlock: boolean) =>
    tempBlock ? (
      <StyledNavigatorLink
        notActiveAdventure={!hasActiveAdventure && label === "ÄVENTYR"}
        onClick={renderAdventureInfoModal}
        route={(nextRoute || {}).route as string}
      >
        {adventureButtonLabel}
      </StyledNavigatorLink>
    ) : (
      <StyledNavigatorLink
        route={getCheckedRoute(route)}
        onClick={renderAdventureInfoModal}
        notActiveAdventure={!hasActiveAdventure && label === "ÄVENTYR"}
      >
        {label}
      </StyledNavigatorLink>
    );

  const renderPlaceables = (data: LinksDataType[]) =>
    data.map((item, index) => (
      <Placeable
        key={index}
        offsetX={item.offsetX}
        offsetY={item.offsetY}
        scale="1"
      >
        {renderLink(
          item.label,
          item.route,
          item.route === ADVENTURE_PICKER_ROUTE && hasActiveAdventure
        )}
      </Placeable>
    ));

  return (
    <StyledWrapper>
      <StyledLinksContainer>
        <StyledTopContainerWrapper>
          <StyledTopContainer>
            <Navigator
              aria-label="Åk till startsidan"
              route={DASHBOARD_ROUTE}
              icon={<HouseIcon color="#FFFFFF" />}
              circular
              overrideStyles={{ ...topButtonStyles, padding: "14px" }}
              skin="primary"
            />
            {(hasActiveAdventure || theme === MIDDLE_SCHOOL) && (
              <Navigator
                aria-label="Åk till övningar"
                route={EXERCISE_ROUTE}
                overrideStyles={{ ...topButtonStyles, padding: "12px" }}
                circular
                icon={
                  <CountIcon style={{ stroke: "#333333", fill: "#FFFFFF" }} />
                }
                skin="primary"
              />
            )}
          </StyledTopContainer>
        </StyledTopContainerWrapper>

        {renderPlaceables(getAtticLink(theme, vertical))}
        <BackgroundImage
          src={getAtticBackgroundImage(theme, !hasActiveAdventure, vertical)} alt={"image of attic"}
        />
      </StyledLinksContainer>
      <AdventureInfoModal
        show={showAdventureInfoModal}
        theme={theme}
        adventureGroup={adventureGroup}
        activeAdventure={activeAdventure}
        currentRoute={currentRoute as string}
        nextRoute={(nextRoute || {}).route as string}
        onAdventureInfoModalClose={setShowAdventureInfoModal}
      />
    </StyledWrapper>
  );
};
