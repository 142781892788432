import styled from 'styled-components'

export const StyledMenuItem = styled.button`
 text-decoration: none;
 width: 100%;
 text-align: left;
 border: none;
 background: none;
 font-size: 20px;
 line-height: 1.8em;
 cursor: pointer;
 font-weight: 400;
 color: #333333;
 font-family: "Muli",sans-serif;
 font-style: normal;
 &:focus {
    outline: none;
    border: 1px solid #277575;
`