import React, { useState, useEffect } from "react";
import { ReactComponent as Planet2 } from "../../../assets/icons/icon_planet_green.svg";
import { ReactComponent as PlanetLocked } from "../../../assets/icons/icon_locked_planet.svg";
import {
  StyledImageActiveBox,
  StyledGoNextArrow,
  StyledGoNextContainer,
  StyledCarouselContainer,
  StyledImageNotActiveBox,
  StyledLock,
  StyledButtonContainer,
  StyledCarouselWrapper,
  StyledButtonWrapper,
  StyledButtonTitle,
  StyledAdventureImage,
  StyledTimeline,
  StyledTimelineBracket,
  StyledLockedStory
} from "./StyledAdventureCarousel";
import { Item, Items } from "./types";
import AliceCarousel from "react-alice-carousel";
import FadeIn from "../../../views/adventurePicker/FadeIn";
import { Themes } from "../../../views/profile/api/types";
import AdventureDetails from "./AdventureDetails";
import { LEFT, RIGHT } from "../../constants";

const responsive = {
  0: { items: 2 },
  880: { items: 3 },
  3000: { items: 3 }
};

type Props = {
  items: Items;
  setSelected: (item: Item) => void;
  selected: Item | null;
  viewSize: "desktop" | "tablet";
  theme: Themes | undefined;
  showDetails: boolean;
};

const AdventureCarousel = ({
  items,
  viewSize,
  setSelected,
  selected,
  theme,
  showDetails
}: Props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [carouselItems, setCarouselItems] = useState<any>();
  const canScroll =
    viewSize === "desktop" ? items.length > 3 : items.length > 2;
  const maxItemsInView = viewSize === "desktop" ? 3 : 2;
  useEffect(() => {
    const renderItems = items.map(i => (
      <StyledButtonWrapper key={i.id}>
        <StyledButtonContainer selected={!!selected && i.id === selected.id}>
          {!i.locked ? (
            <StyledImageActiveBox
              onClick={() => setSelected(i)}
              data-value={i.id}
              aria-label={`Äventyr ${i.title}`}
            >
              {i.image_url ? (
                <StyledAdventureImage  alt={`Illustration från äventyret ${i.title}`} src={i.image_url} />
              ) : (
                <Planet2 />
              )}
            </StyledImageActiveBox>
          ) : (
            <StyledImageNotActiveBox data-value={i.id}>
              {theme === "middle-school" ? (
                <StyledLockedStory>
                  <StyledLock />
                </StyledLockedStory>
              ) : (
                <PlanetLocked>
                  <StyledLock />
                </PlanetLocked>
              )}
            </StyledImageNotActiveBox>
          )}
        </StyledButtonContainer>
        <StyledButtonTitle>{i.locked ? "" : i.title}</StyledButtonTitle>
        {theme === "middle-school" && <StyledTimelineBracket />}
      </StyledButtonWrapper>
    ));
    setCarouselItems(renderItems);
  }, [items, setCarouselItems, selected, setSelected, theme]);

  /**
   * Finds "open" adventures not showing in the view, depending on direction.
   * Depending on constant maxItemsInView which is either 3 (desktop) or 2 (tablet)
   * We can check the hidden indexes. The null check is there for when a user rotates the screen.
   * Then maxItemsInView will change but not the state activeIndex.
   * @param direction
   * @returns
   */
  const checkHiddenActiveAdventure = (direction: string) => {
    if (direction === RIGHT) {
      return items.some(
        item =>
          items[activeIndex + maxItemsInView - 1] &&
          items[activeIndex + maxItemsInView - 1].weight < item.weight &&
          !item.locked
      );
    }

    if (direction === LEFT) {
      return items.some(
        item => items[activeIndex].weight > item.weight && !item.locked
      );
    }

    return false;
  };

  const slidePrev = () => setActiveIndex(activeIndex - 1);
  const slideNext = () => setActiveIndex(activeIndex + 1);
  const syncActiveIndex = ({ item }: { item: number }) => setActiveIndex(item);
  return (
    <>
      {showDetails ? (
        <FadeIn fadeKey="adventureDetails" isIn={showDetails}>
          <AdventureDetails
            viewSize={viewSize}
            details={selected?.description}
          />
        </FadeIn>
      ) : (
        <FadeIn fadeKey="carousel" isIn={Boolean(carouselItems)}>
          <>
            {carouselItems && (
              <StyledCarouselContainer>
                <StyledGoNextContainer
                  hasActiveAdventures={checkHiddenActiveAdventure(LEFT)}
                  disabled={activeIndex === 0 || !canScroll}
                  direction="left"
                  onClick={slidePrev}
                  aria-label="scrolla tillbaka"
                >
                  <StyledGoNextArrow />
                </StyledGoNextContainer>
                <StyledCarouselWrapper>
                  <AliceCarousel
                    mouseTracking
                    items={carouselItems}
                    disableDotsControls
                    disableButtonsControls
                    responsive={responsive}
                    activeIndex={activeIndex}
                    onSlideChanged={syncActiveIndex}
                  />
                </StyledCarouselWrapper>
                <StyledGoNextContainer
                  hasActiveAdventures={checkHiddenActiveAdventure(RIGHT)}
                  disabled={
                    activeIndex === items.length - maxItemsInView || !canScroll
                  }
                  direction="right"
                  onClick={slideNext}
                  aria-label="scrolla framåt"
                >
                  <StyledGoNextArrow />
                </StyledGoNextContainer>
              </StyledCarouselContainer>
            )}
            {theme === "middle-school" && <StyledTimeline />}
          </>
        </FadeIn>
      )}
    </>
  );
};

export default AdventureCarousel;
