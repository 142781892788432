import styled from "styled-components";

export const PLAYING_COLOR = "#F9F6F6";
export const STOPPED_COLOR = "#277575";

export interface ButtonProps {
  active: boolean;
}

export const StyledAudioButton = styled.button`
  z-index: 999999;
  svg{margin: 0 auto}
  flex-shrink: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  outline: none;
  box-shadow: rgb(223, 220, 220) 0px 1px 0px 0px inset, rgba(0, 0, 0, 0.35) 0px 0px 14px 0px;
  background-color: ${({ active }: ButtonProps) =>
    active ? STOPPED_COLOR : PLAYING_COLOR};
}

&:hover,
  &:focus {
    box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 12px 2px;
    transition: box-shadow 0.15s ease-in-out;  
  }`;
    
