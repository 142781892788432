import styled from "styled-components";
import { S, M, L, XL } from "../../../../constants";

export interface ButtonProps {
  isSelected: boolean;
  isGivenAnswer: boolean;
  answerState: string;
  retry: boolean;
  isCorrectAnswer: boolean;
  displayCorrectAnswer: boolean;
  size?: string;
  fontSize?: string;
}

interface ButtonLayoutProps {
  layout?: string;
}

interface ButtonMode {
  btnColor: string;
  icon: string;
}

interface ButtonModes {
  [key: string]: ButtonMode;
}

const getFontSize = (fontSize: string | undefined) => {
  switch (fontSize) {
    case S:
      return "2vmin";
    case M:
      return "3vmin";
    case L:
      return "5vmin";
    case XL:
      return "7vmin";
    default:
      return "3vmin";
  }
};

const SelectedIcon =
  "\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='3' cy='3' r='3' fill='%23277575' stroke='%23277575' transform='translate(7 7)'/%3E%3Ccircle cx='10' cy='10' r='9' stroke='%23277575'/%3E%3C/g%3E%3C/svg%3E%0A\"";
const CorrectIcon =
  "\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cg fill='none' fill-rule='evenodd' stroke='%231FC84C' transform='translate(1 1)'%3E%3Ccircle cx='9' cy='9' r='9' fill='%23F9F6F6'/%3E%3Cpath stroke-width='2' d='M14,5 C9.949,10.333 7.949,13 8,13 C8.051,13 6.718,11.667 4,9'/%3E%3C/g%3E%3C/svg%3E \"";
const TryagainIcon =
  "\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='10' cy='10' r='9' fill='%23F9F6F6' stroke='%23FAAC2C'/%3E%3Cg fill='%23FAAC2C' transform='rotate(-45 12.121 4.879)'%3E%3Crect width='2' height='14' x='6' rx='1'/%3E%3Crect width='2' height='14' x='6' transform='rotate(90 7 7)' rx='1'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E \"";
const WrongIcon =
  "\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='10' cy='10' r='9' fill='%23F9F6F6' stroke='%23EC4C3F'/%3E%3Cg fill='%23EC4C3F' transform='rotate(-45 12.121 4.879)'%3E%3Crect width='2' height='14' x='6' rx='1'/%3E%3Crect width='2' height='14' x='6' transform='rotate(90 7 7)' rx='1'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A\"";

const borderColor = "#277575";
const buttonBg = "#FFFFFF";
const again = "#FDAf2D";
const wrong = "#EC4C3F";
const correct = "#1FC84C";
const textColor = "#333333";

export const btnModes: ButtonModes = {
  CORRECT: { btnColor: correct, icon: CorrectIcon },
  INCORRECT: { btnColor: wrong, icon: WrongIcon },
  TRY_AGAIN: { btnColor: again, icon: TryagainIcon },
  DEFAULT: { btnColor: buttonBg, icon: SelectedIcon },
  SELECTED: { btnColor: buttonBg, icon: SelectedIcon },
};

const hoverFocusStyles = `
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.5);
  outline: none;
  &::after {
    content: " ";
    position: absolute;
    right: -14px;
    top: -12px;
  }
`;

const btnSelected = () => {
  return `
    box-shadow: 0 0 7px 0 rgba(0,0,0,0.5);
    padding: 0;
    &::after {
       right: -14px;
       top: -12px;
       box-shadow: none;
       border: none;
       background-color: ${buttonBg};
       background-image: url(${SelectedIcon});
    }
 `;
};

const btnGivenAnswer = (answerState: string) => `
    border-color: ${btnModes[answerState].btnColor};
    color: ${textColor};
    &::after {
      right: -11px;
      top: -9px;
      border-radius: 24px;
      background-color: ${buttonBg};
      background-image: url(${btnModes[answerState].icon});
    }
  `;

const btnCorrectOrWrong = (answerState: string) => `
    border-color: ${btnModes[answerState].btnColor};
    color: ${textColor};
    &::after {
      right: -11px;
      top: -9px;
      border-radius: 24px;
      background-color: ${buttonBg};
      background-image: url(${btnModes[answerState].icon});
    }
  `;

const btnDefault = () => {
  return ``;
};

const btnStyle = (
  isCorrectAnswer: boolean = false,
  displayCorrectAnswer: boolean = false,
  isSelected: boolean = false,
  isGivenAnswer: boolean = false,
  answerState: string = "DEFAULT",
  retry: boolean
) => {
  if (displayCorrectAnswer) {
    return isCorrectAnswer ? btnCorrectOrWrong("CORRECT") : btnDefault();
  } else if (
    (isSelected && answerState === "CORRECT") ||
    (isSelected && answerState === "INCORRECT")
  ) {
    answerState = isCorrectAnswer ? "CORRECT" : answerState;
    return btnCorrectOrWrong(answerState);
  } else if (isGivenAnswer && answerState === "TRY_AGAIN" && !retry) {
    return btnGivenAnswer(answerState);
  } else if (isSelected === true) {
    return btnSelected();
  }
};

const getSmallButton = () => `
  margin: 0 4px;
  max-width: 300px;
  max-height: 114px;
  height:  100%;
  `;

const getMediumButton = () => `
  margin: 4px;
  flex-basis: 44%;
  max-height: 50%;
  height: auto;
  `;

const getLargeButton = () => `
  margin: 4px;
  flex-basis: auto;
  max-height: 100%;
`;

const getButtonSize = (size: string = "1") => {
  switch (size) {
    case "1":
      return getSmallButton();

    case "2":
      return getSmallButton();

    case "3":
      return getMediumButton();

    case "4":
      return getLargeButton();

    default:
      getSmallButton();
  }
};

const getSingleRowLayout = () => {
  return `
    flex-wrap: nowrap;
    align-items: center;
  `;
};

const getMultiRowLayout = () => {
  return `
    flex-wrap: wrap;
    align-items: stretch;
  `;
};

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0;
  flex: 1;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;

  ${(props: ButtonLayoutProps) =>
    props.layout === "1" || props.layout === "2"
      ? getSingleRowLayout()
      : getMultiRowLayout()};
`;

export const StyledButton = styled.button`
  position: relative;
  color: ${borderColor};
  background-color: ${buttonBg};
  border-radius: 12px;
  border: 2px solid ${borderColor};
  padding: 0;
  width: 100%;

  cursor: pointer;
  font-family: "Muli", sans-serif;
  font-size: ${(props: ButtonProps) => getFontSize(props.fontSize)};
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.76px;
  box-sizing: border-box;

  @media (max-width: 740px) {
    font-size: 18px;
  }


  &::after {
    content: " ";
    position: absolute;
    right: -14px;
    top: -12px;
    border-radius: 24px;
    width: 24px;
    height: 24px;
    font-weight: 700;
    font-size: 45px;
    line-height: 24px;
    background-size: contain;
  }

  @media (hover: hover) {
    &:hover {
      ${hoverFocusStyles}
    }
  }
  
  &:focus {
    ${hoverFocusStyles}
  }

  .svg-wrapper,
  .img-wrapper {
    position: static;
    height: auto;
  }

  > svg {
    max-height: 100%;
    max-width: 100%;
  }

  img {
    border-radius: 12px;
    max-height: 100%;
    max-width: 100%;
  }

  ${(props: ButtonProps) => getButtonSize(props.size)};
  ${(props: ButtonProps) =>
    btnStyle(
      props.isCorrectAnswer,
      props.displayCorrectAnswer,
      props.isSelected,
      props.isGivenAnswer,
      props.answerState,
      props.retry
    )};
`;

export const StyledNumberSequence = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  width: 100%;
  max-width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;

  span {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    &:nth-child(odd) {
      background-color: #f3f7f6;
    }
  }
`;
