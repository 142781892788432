import styled from "styled-components";
import { Navigator } from "../Navigator";


export const StyledNavigator = styled(Navigator)`
display: flex;
height: ${props => props.width};
width: ${props => props.width};
padding: ${props => (props.square ? "6px" : "20px")};
background-color: ${props => props.inverted ? "#fff" : props.theme.themePrimary};
color: ${props => props.theme.white};
border-radius: ${props => (props.square ? "4px" : "50%")};
justify-content: center;
align-items: center;
opacity: ${props => (props.disabled ? "0.3" : "1")};
cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
transition: background-color 0.2s ease;

&:hover {
  background-color: #1f5d5d;
}
&:focus {
  background-color: #1f5d5d;
}
&:active {
  background-color: #1f5d5d;
}
`;
